@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@layer base {

  body {
    @apply font-[Asap] bg-[#3aafa9] text-[#17252a];
  }
  h1 {
    @apply font-[Lato];
  }
  li {
    @apply text-[#1f5a58] text-lg sm:text-xl font-[Asap];
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
